.form {
	display: grid;
	grid-template-columns: 5fr 1fr;
	column-gap: var(--hdsplus-spacing-05);
	margin-bottom: -1rem;

	@media (--large) {
		grid-template-columns: 5fr 1fr 4fr;
	}

	& [data-field-id='Email'] {
		& label {
			display: none;
		}
	}

	& [data-field-id='Consent_Privacy_Policy__c'] {
		grid-column: 1 / -1;

		@media (--large) {
			grid-column: 1 / 2;
			grid-row: 2;
		}

		& label {
			font-weight: 400;
			color: var(--token-color-foreground-faint);
		}
	}

	& [data-field-id='privacy-policy-text'] {
		grid-column: 1 / -1;

		@media (--large) {
			margin-left: var(--hdsplus-spacing-08);
			grid-column: 3 / -1;
			grid-row: 1 / span 2;
		}
	}

	& button {
		grid-column: 2 / -1;
		grid-row: 1;
		align-self: start;
		margin-top: var(--hdsplus-spacing-03);

		@media (--large) {
			grid-column: 2 / span 1;
		}
	}
}
