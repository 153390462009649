.basePadding {
	--padding: var(--hdsplus-spacing-07);
	--horizontalPadding: var(--hdsplus-spacing-07);

	@media (--medium-up) {
		--padding: var(--hdsplus-spacing-08) var(--hdsplus-spacing-09);
		--horizontalPadding: var(--hdsplus-spacing-09);
	}

	@media (--large) {
		--padding: var(--hdsplus-spacing-08) var(--hdsplus-spacing-13);
		--horizontalPadding: var(--hdsplus-spacing-13);
	}
}

.header {
	height: 100%;
	padding: var(--hdsplus-spacing-07) var(--horizontalPadding);
}

.detailsHeader {
	composes: basePadding;
	composes: header;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	column-gap: var(--hdsplus-spacing-07);

	@media (--medium-up) {
		padding: var(--hdsplus-spacing-08) var(--horizontalPadding);
	}

	@media (--large) {
		row-gap: var(--hdsplus-spacing-03);
	}
}

.optionsHeader {
	composes: basePadding;
	composes: header;
	display: flex;
	align-items: flex-end;

	@media (--medium-up) {
		padding: var(--hdsplus-spacing-11) var(--horizontalPadding)
			var(--hdsplus-spacing-08);
	}
}

.label {
	composes: hdsplus-typography-label from global;
	color: var(--token-color-foreground-faint);
	font-weight: 600;
	flex: 1 1 100%;

	@nest :global(.hashiconfConference) & {
		color: var(--token-color-foreground-primary);
	}

	@nest :global(.hashidaysConference) & {
		color: #2e71e5;
	}

	@media (--medium-up) {
		grid-column: 1 / -1;
	}
}

.baseText {
	color: var(--token-color-foreground-strong);
	font-family: var(--hdsplus-typography-display-expressive-200-font-family);
	font-size: var(--hdsplus-typography-display-expressive-200-font-size);
	line-height: var(--hdsplus-typography-display-expressive-200-line-height);
	letter-spacing: var(
		--hdsplus-typography-display-expressive-200-letter-spacing
	);
	font-weight: 700;

	@media (--large) {
		font-family: var(--hdsplus-typography-display-expressive-300-font-family);
		font-size: var(--hdsplus-typography-display-expressive-300-font-size);
		line-height: var(--hdsplus-typography-display-expressive-300-line-height);
		letter-spacing: var(
			--hdsplus-typography-display-expressive-300-letter-spacing
		);
	}
}

.headline {
	composes: baseText;
}

.price {
	composes: baseText;
	align-self: end;

	@media (--medium-up) {
		justify-self: end;
	}
}

.details {
	composes: basePadding;
	list-style-type: none;
	padding: var(--hdsplus-spacing-07) var(--horizontalPadding)
		var(--hdsplus-spacing-08);
	margin: 0;
	display: grid;
	gap: var(--hdsplus-spacing-07);

	@media (--medium-up) {
		padding: var(--hdsplus-spacing-09) var(--horizontalPadding)
			var(--hdsplus-spacing-11);
	}

	@media (--large) {
		padding: var(--hdsplus-spacing-09) var(--horizontalPadding)
			var(--hdsplus-spacing-12);
	}
}

.detail {
	display: grid;
	grid-template-columns: min-content 1fr;
	gap: var(--hdsplus-spacing-02) var(--hdsplus-spacing-04);
}

.detailIcon {
	height: 27px;
	display: flex;
	align-items: center;
	color: var(--token-color-foreground-faint);

	@nest :global(.hashidaysConference) & {
		color: var(--hdsplus-color-vagrant-button-background-color);
	}

	@nest :global(.hashiconfConference) & {
		color: var(--hdsplus-color-terraform-button-background-color);
	}
}

.detailTitle {
	composes: hdsplus-typography-body-300 from global;
	font-weight: 500;
	color: var(--token-color-foreground-strong);
}

.detailDescription {
	composes: hdsplus-typography-body-300 from global;
	font-weight: 400;
	color: var(--token-color-foreground-faint);
	grid-column: 2 / -1;
}

.options {
	composes: basePadding;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
	gap: var(--hdsplus-spacing-06);
	padding: var(--hdsplus-spacing-06) var(--horizontalPadding) 44px;

	@media (--medium-up) {
		padding-top: var(--hdsplus-spacing-09);
		padding-bottom: var(--hdsplus-spacing-13);
	}

	@media (--large) {
		padding-top: var(--hdsplus-spacing-10);
	}
}

.optionsInner {
	list-style-type: none;
	padding: 0;
	margin: 0;
	display: grid;
	gap: var(--hdsplus-spacing-03);
}

.option {
	position: relative;
	display: grid;
	grid-template-columns: max-content 1fr min-content;
	align-items: center;
	gap: var(--hdsplus-spacing-07);
	border: 1px solid var(--token-color-border-primary);
	border-radius: var(--hdsplus-spacing-03);
	padding: var(--hdsplus-spacing-04) var(--hdsplus-spacing-07)
		var(--hdsplus-spacing-04) var(--hdsplus-spacing-04);

	@media (--medium-up) {
		padding-right: var(--hdsplus-spacing-08);
	}

	@nest :global(.hashidaysConference) & {
		background: var(--token-color-surface-primary);
		border: 1px solid var(--token-color-border-primary);

		&::before {
			position: absolute;
			top: 50%;
			right: 23px;
			transform: translateY(-50%);
			content: '';
			width: 15px;
			height: 15px;
			border-radius: 9999px;
			border: 1px solid var(--token-form-control-base-border-color-default);
		}

		&:global(.isChecked) {
			background: linear-gradient(
						var(--token-color-surface-primary),
						var(--token-color-surface-primary)
					)
					padding-box,
				linear-gradient(var(--conferenceGradient)) border-box;
			border-color: transparent;

			&::before {
				background: linear-gradient(
					132deg,
					#a067da 20.23%,
					#4070dd 45.24%,
					#02a8ef 79.77%
				);
				border: none;
			}

			&::after {
				position: absolute;
				top: 50%;
				right: 28px;
				transform: translateY(-50%);
				content: '';
				width: 5px;
				height: 5px;
				border-radius: 9999px;
				background-color: white;
			}
		}

		& input {
			position: absolute;
			clip: rect(1px, 1px, 1px, 1px);
			padding: 0;
			border: 0;
			height: 1px;
			width: 1px;
			overflow: hidden;
		}
	}

	@nest :global(.hashiconfConference) & {
		&::before {
			position: absolute;
			top: 50%;
			right: 23px;
			transform: translateY(-50%);
			content: '';
			width: 15px;
			height: 15px;
			border-radius: 9999px;
			border: 1px solid var(--token-form-control-base-border-color-default);
		}

		&:global(.isChecked) {
			background: linear-gradient(
						var(--token-color-surface-primary),
						var(--token-color-surface-primary)
					)
					padding-box,
				linear-gradient(to right, var(--conferenceGradientFull)) border-box;
			border-color: transparent;

			&::before {
				background: linear-gradient(
					to bottom right,
					var(--conferenceGradientFull)
				);
				border: none;
			}

			&::after {
				position: absolute;
				top: 50%;
				right: 28px;
				transform: translateY(-50%);
				content: '';
				width: 5px;
				height: 5px;
				border-radius: 9999px;
				background-color: white;
			}
		}

		& input {
			position: absolute;
			clip: rect(1px, 1px, 1px, 1px);
			padding: 0;
			border: 0;
			height: 1px;
			width: 1px;
			overflow: hidden;
		}
	}

	@nest .disabled & {
		background: none;
		border: 1px solid var(--token-color-border-primary);

		&::before {
			background: none;
			border: 1px solid var(--token-form-control-base-border-color-default);
		}

		&::after {
			content: none;
		}
	}

	/* Need to duplicate the styles here in order to apply them to HashiDays */

	/* TODO Determine why just `@nest .disabled &` doesn't apply these styles generally */
	@nest :global(.hashidaysConference) .disabled & {
		background: var(--token-color-surface-faint);
		border: 1px solid var(--token-color-border-primary);

		&::before {
			background: var(--token-color-surface-faint);
			border: 1px solid var(--token-form-control-base-border-color-default);
		}

		&::after {
			content: none;
		}
	}
}

.optionThumbnail {
	width: 64px;
	height: 64px;

	& img {
		max-width: 100%;
		object-fit: cover;
		height: 100%;

		@nest .disabled & {
			filter: grayscale(100%);
		}
	}
}

.optionText {
	color: var(--token-color-foreground-strong);
	font-weight: 700;

	@nest .disabled & {
		color: var(--token-color-foreground-faint);
	}
}

.cta {
	display: flex;
	justify-content: flex-end;
}
